<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useShowStore } from '@/stores/show';
import { ICalendarSvg2Item } from '@/types/image';

console.log('ShowSingle loading...');

const showStore = useShowStore();
const route = useRoute();
const id = route.params.id;

const calendarId = Number.parseInt(typeof id == 'string' ? id : id[0]);
const items = ref([] as ICalendarSvg2Item[]);
const showIndex = ref(0);

getSvg();

function getSvg() {
  items.value = showStore.calendarSvgs.find(c => c.id == calendarId).items;
}

function itemSelected(name: string){
  showIndex.value = items.value.findIndex(v => v.name === name);  
}
</script>

<template>
  <div class="full-page">
    <svg class="svg-large" v-html="items[showIndex].svg"></svg>
    <div class="pages">
      <div v-for="s in items" @click="itemSelected(s.name)">
        <svg class="svg-small" v-html="s.svg"></svg>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.full-page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #eee;
}

.pages {
  margin: 0 2rem;
  display: flex;
  overflow-x: auto;
}
.svg-large {
  width: 800px;
  height: 800px;
  margin: 2rem;
  background-color: #fff;
}

.svg-small {
  width: 200px;
  height: 200px;
  margin: 1rem;
  background-color: #fff;
}
</style>
