import { defineStore } from 'pinia';

export const useModalStore = defineStore('modal', {
  state: () => ({
    isOpen: {}, // e.g. confirm, createLightbox, modalImage
    data: {} as any
  }),

  actions: {
    open(modal: string, data?:object) {
      this.data = data;
      this.isOpen[modal] = true;
    },
    close(modal: string) {
      this.isOpen[modal] = false;
      //this.data = null;
    },
    toggle(modal: string) {
      this.isOpen[modal] = !this.isOpen[modal];
      return this.isOpen[modal];
    }
  },
});
