import { defineStore } from 'pinia';
import { BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser';
import { IAccount } from '@/types/image';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    account: null as IAccount,
    msalConfig: {
      auth: {
        clientId: '52dbe6c5-15cf-4474-8294-e2941bd1cdfb',
        authority: 'https://login.microsoftonline.com/19269dc1-8b0e-4c8c-9647-1aebbab40de3', // TenantId
        // knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: '/'
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true, 
      },
    },
    accessToken: '',
  }),
  actions: {
    async init() {
      this.$msalInstance = new PublicClientApplication(this.msalConfig);
    },
    async SignIn() {
      await this.$msalInstance
        .loginPopup({})
        .then(() => {
          const myAccounts = this.$msalInstance.getAllAccounts();
          this.account = myAccounts[0];
          console.log('SignIn: ', this.account)
        })
        .catch((error) => {
          console.error(`error during authentication: ${error}`);
        });
    },
    async SignOut() {
      await this.$msalInstance
        .logout({})
        .then(() => {
          this.account = null;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
});
